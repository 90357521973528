
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider, StyleSheetManager, 
// eslint-disable-next-line import/named
DefaultTheme, } from 'styled-components';
import { FonomaBaseTheme } from '../modules/common';
import { RetopeBaseTheme } from '../modules/common/styles/themes/retope-base-theme';
import { FonomaGlobalStyle } from '../global-styles/fonoma-global-style';
import { TeloaGlobalStyle } from '../global-styles/teloa-global-style';
import { analytics } from '../modules/analytics/analytics';
import { FonomaDefaultSeo } from '../components/seo/fonoma-default-seo';
import { getEventName } from '../modules/util/web-metric/get-event-name';
import { PROJECT_ENVS } from '../modules/util/constants';
import isNode from 'detect-node';
import { Metric } from '../modules/util/web-metric/metric-interface';
import 'swiper/swiper-bundle.css';
import '@fontsource/inter/latin-300.css';
import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-600.css';
import '@fontsource/inter/latin-700.css';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { rollbarConfig } from '../modules/rollbar/rollbar-config';
import { GlobalLoadingIndicatorProvider } from '../modules/global-indicator-provider/context';
import { ReviewsIoContextProvider } from '../components/reviews-io/reviews-io-context';
import isPropValid from '@emotion/is-prop-valid';
import { handleCookiebotResponse } from '../modules/util/handle-ccokie-bot-response';
/**
 * Custom Nextjs App component to enable i18n. Injects the FonomaBaseTheme to all child components.
 */
function FonomaApp({ Component, pageProps }: AppProps) {
    let theme;
    let GlobalStyle;
    switch (process.env.NEXT_PUBLIC_PROJECT_NAME as string) {
        case 'teloa':
            theme = RetopeBaseTheme;
            GlobalStyle = TeloaGlobalStyle;
            break;
        case 'fonoma':
            theme = FonomaBaseTheme;
            GlobalStyle = FonomaGlobalStyle;
            break;
    }
    React.useEffect(() => {
        if (!isNode) {
            if (process.env.NEXT_PUBLIC_USE_COOKIE_BOT === '1') {
                // This effect handles the relationship between the cookie consent
                // and segment (analytics). Until the user hasn't consented to the use of cookies
                // we will not load segment.
                // Check if the user has already accepted the cookies
                if (window.Cookiebot && window.Cookiebot.hasResponse) {
                    handleCookiebotResponse();
                }
                else {
                    window.addEventListener('CookiebotOnAccept', handleCookiebotResponse);
                }
            }
            else {
                // If we are not using cookiebot, load segment right away.
                analytics.load();
            }
        }
    }, []);
    return (<Provider config={rollbarConfig}>
            <ErrorBoundary>
                <ThemeProvider theme={theme as DefaultTheme}>
                    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                        <GlobalLoadingIndicatorProvider initialState={{
            show: false,
            opacity: 0.75,
            color: '#ffffff',
        }}>
                            <ReviewsIoContextProvider>
                                <>
                                    <FonomaDefaultSeo />
                                    <Component {...pageProps}/>
                                    <GlobalStyle />
                                </>
                            </ReviewsIoContextProvider>
                        </GlobalLoadingIndicatorProvider>
                    </StyleSheetManager>
                </ThemeProvider>
            </ErrorBoundary>
        </Provider>);
}
export function reportWebVitals(metric: Metric): void {
    if (!isNode) {
        if (process.env.NEXT_PUBLIC_PROJECT_ENV !== PROJECT_ENVS.PRODUCTION) {
            console.log(metric);
        }
        void analytics.track(getEventName(metric), {
            ...metric,
            ...{ url: window.location.pathname },
        });
    }
}
// This implements the default behavior from styled-components v5
// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
function shouldForwardProp(propName, target) {
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
}
const __Next_Translate__Page__19271e85aff__ = FonomaApp;

    export default __appWithI18n(__Next_Translate__Page__19271e85aff__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  